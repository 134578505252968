import * as React from "react"
import { Context, createContext, FC } from "react"
import { getConfig } from "../../config/index"
import {
  DEFAULT_ORDER_CONTEXT_TYPE,
  OrderContextType,
  OrderProvider,
} from "@social-supermarket/social-supermarket-components"

export const GiftOrderContext: Context<OrderContextType> = createContext<OrderContextType>({
  ...DEFAULT_ORDER_CONTEXT_TYPE,
})

interface Props {
  children: React.ReactNode
}

const GiftOrderProvider: FC<Props> = ({ children }) => {
  return (
    <OrderProvider
      baseUrl={getConfig().baseUrl}
      category={"GIFTING"}
      Context={GiftOrderContext}
      children={children}
      mainOrderTab="recipients"
    />
  )
}

export default GiftOrderProvider
