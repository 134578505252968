import * as React from "react"
import { Context, createContext, FC, useEffect, useState } from "react"
import {
  DEFAULT_GIFT_CONTEXT_TYPE,
  DEFAULT_GIFT_STATE_TYPE,
  GiftContextType,
  GiftStateType,
} from "../model/GiftContextType"

export const GiftContext: Context<GiftContextType> = createContext<GiftContextType>({
  ...DEFAULT_GIFT_CONTEXT_TYPE,
})

interface Props {
  children: React.ReactNode
}

const GiftProviders: FC<Props> = ({ children }) => {
  const [giftState, setGiftState] = useState<GiftStateType>({ ...DEFAULT_GIFT_STATE_TYPE })
  const [page, setPage] = useState<number>(0)

  useEffect(() => {
    setGiftState(prev => ({ ...prev, choices: [...prev.choices] }))
  }, [page])

  const updateState = (newState: GiftStateType) => {
    setGiftState({ ...newState })
  }

  const addPage = () => {
    setPage(page + 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const removePage = () => {
    setPage(page - 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const reset = () => {
    setGiftState({ ...DEFAULT_GIFT_STATE_TYPE })
    setPage(0)
  }

  return (
    <GiftContext.Provider
      value={{
        giftState,
        updateState: setGiftState,
        page,
        addPage,
        removePage,
        reset,
      }}
    >
      {children}
    </GiftContext.Provider>
  )
}

export default GiftProviders
