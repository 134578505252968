import local from "./local"
import dev from "./dev"
import uat from "./uat"
import prod from "./prod"

export const getConfig = () => {
  if (typeof window === "undefined") {
    return prod
  }

  const { href } = window.location

  if (href.includes("localhost")) {
    return uat
  }
  if (href.includes("locallocal.")) {
    return local
  } else if (href.includes("localdev.")) {
    return dev
  } else if (
    href.includes("localuat.") ||
    href.includes("gatsbyjs.io") ||
    href.includes("gtsb.io") ||
    href.includes("gifting-qa") ||
    href.includes("gifting-demo") ||
    href.includes("app-demo")
  ) {
    return uat
  } else {
    return prod
  }
}

export const getFrontendUrl = () => {
  const { protocol, host } = window.location
  return `${protocol}//${host}`
}
