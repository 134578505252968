import ProductSelectionType from "../pages/gift/choice/ProductSelectionType"
import OrderType from "./OrderType"

export type GiftListContextType = {
  choices: ProductSelectionType[]
  addChoices: (choices: ProductSelectionType[]) => void
  removeChoice: (choice: ProductSelectionType) => void
  cancel: () => void
  allGiftLists: OrderType[]
  updateGiftList: (list: OrderType) => void
  isLoadingGiftPages: boolean
}
export const DEFAULT_GIFT_LIST_CONTEXT_TYPE: GiftListContextType = {
  choices: [],
  addChoices: choice => null,
  removeChoice: choice => null,
  cancel: () => null,
  allGiftLists: [],
  updateGiftList: () => null,
  isLoadingGiftPages: true,
}
