import { clearDuplicateImages } from "./imageUtils"
import { isANumber } from "./generalUtil"
import ProductSelectionType from "../pages/gift/choice/ProductSelectionType"
import { ImageType, ProductType, VariantType } from "@social-supermarket/social-supermarket-model"
import { formatPrice } from "@social-supermarket/social-supermarket-model"

export const getStockQuantityFromProduct = (product: ProductType): string => {
  if (product.variants && product.variants.length > 0) {
    const stockValues: number[] = []
    product.variants.forEach(variant => {
      if (isANumber(variant.stockQuantity)) {
        stockValues.push(variant.stockQuantity)
      }
    })
    if (stockValues.length === 1) {
      return `${stockValues[0]}`
    } else if (stockValues.length > 1) {
      return `${Math.min.apply(Math, stockValues)} - ${Math.max.apply(Math, stockValues)}`
    } else {
      return ``
    }
  } else {
    return isANumber(product.stockQuantity) ? `${product.stockQuantity}` : ""
  }
}

export const getExVatShipping = (product: ProductType, variant: VariantType) => {
  if (variant) {
    return formatPrice(variant.singleShippingCost / (variant.price / variant.exVatPrice))
  } else {
    return formatPrice(product.singleShippingCost / (product.price / product.exVatPrice))
  }
}

export const cleanString = (name: string): string => {
  return name
    .replace(/[.,\/#!$%\^&\*;:{}=\_`~()]/g, "")
    .replace(/ /g, "-")
    .toLowerCase()
}

export const getAllImages = (product: ProductType): ImageType[] => {
  return clearDuplicateImages(
    [
      product.image,
      ...product.gallery,
      ...(product.variants ? product.variants.map(v => v.image) : []),
    ].filter(i => i)
  )
}

export const isNoAddressProduct = (product: ProductType): boolean => {
  return product.productTags.some(tag => tag.slug === "no-address")
}

export const doSelectionsMatch = (
  selectionA: ProductSelectionType,
  selectionB: ProductSelectionType
): boolean => {
  return (
    selectionA.product.productId === selectionB.product.productId &&
    selectionA.variant?.variantId === selectionB.variant?.variantId
  )
}

export const isFullySelected = (
  product: ProductType,
  choices: ProductSelectionType[],
  inStockVariants: VariantType[]
): boolean => {
  if (product.variants) {
    if (inStockVariants) {
      return inStockVariants.every(variant =>
        choices.some(choice => doSelectionsMatch(choice, { product, variant }))
      )
    } else {
      return product.variants.every(variant =>
        choices.some(choice => doSelectionsMatch(choice, { product, variant }))
      )
    }
  } else {
    return choices.some(choice => doSelectionsMatch(choice, { product, variant: null }))
  }
}
