import ProductSelectionType from "../pages/gift/choice/ProductSelectionType"
import { Dispatch, SetStateAction } from "react"

export interface GiftStateType {
  orderName: string
  giftNote: string
  nRecipients: number
  choice: boolean
  choices: ProductSelectionType[]
}

export type GiftContextType = {
  giftState: GiftStateType
  page: number
  addPage: () => void
  removePage: () => void
  updateState: Dispatch<SetStateAction<GiftStateType>>
  reset: () => void
}

export const DEFAULT_GIFT_STATE_TYPE: GiftStateType = {
  orderName: "",
  giftNote: "",
  nRecipients: undefined,
  choice: true,
  choices: [],
}
export const DEFAULT_GIFT_CONTEXT_TYPE: GiftContextType = {
  giftState: { ...DEFAULT_GIFT_STATE_TYPE },
  page: 0,
  addPage: () => null,
  removePage: () => null,
  updateState: () => null,
  reset: () => null,
}
