import "semantic-ui-css/semantic.min.css"
import "./src/style/index.scss"
import "./src/style/blog.scss"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-community/dist/styles/ag-theme-balham.css"

import "rsuite/dist/rsuite-no-reset.min.css"

import "whatwg-fetch"
import "react-datepicker/dist/react-datepicker.css"
import sbjs from "sourcebuster"

const React = require("react")

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"
import GlobalProvider from "./src/social-supermarket/context/GlobalProvider"
import { track } from "./src/social-supermarket/tracking/googleTracker"
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

sbjs.init()

export const wrapRootElement = ({ element, props }) => (
  <GlobalProvider {...props}>{element}</GlobalProvider>
)

window.displayGrowl = message => {
  $(".growl-notice").fadeIn().html(message)
  setTimeout(function () {
    $(".growl-notice").fadeOut()
  }, 2000)
}

export const onPreRouteUpdate = () => {
  track({
    ecommerce: {
      impressions: undefined,
      click: undefined,
      detail: undefined,
      remove: undefined,
      add: undefined,
    },
  })
}

// You can delete this file if you're not using it
